import { Ishop, Iapp, IshopStatus } from "./types";

export const DEFAULT_SHOP_DATA: Ishop = {
  pageInit: false,
  shop: "",
  locale: "",
  orgid: "",
  isIframe: false,
};

export const DEFAULT_APP_DATA: Iapp = {
  appRunning: false,
};

export const USER_SCREEN = {
  register_form: -1,
  facebook_login: 0,
  create_business: 1,
  assign_page: 2,
  assign_ad_account: 3,
  listing_campaign: 4,
  ads_created: 5,
};

export const DEFAULT_SHOP_STATUS_DATA: IshopStatus = {
  init: false,
  isFacebookAdsEnabled: false,
  isLoginFacebook: false,
  isOnBoardingCompleted: false,
  onBoardingStep: 0,
  planStatus: "",
  userScreen: 0,
};
