import { lazy, Suspense } from "react";

interface ILazyLoad {
  CallBackFnc: any;
  fallback?: any;
}

const defaultProps = {
  CallBackFnc: () => {},
  fallback: null,
};

// Initial component Loadable
const Loadable = (Component, fallback) => (props) => {
  return (
    <Suspense fallback={fallback}>
      <Component {...props} />
    </Suspense>
  );
};

export const LazyLoad = ({ CallBackFnc, fallback = null }: ILazyLoad) => {
  return Loadable(lazy(CallBackFnc), fallback);
};

LazyLoad.defaultProps = defaultProps;
