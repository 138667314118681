import { memo } from 'react';
import RequireAuth from './requiredAuth';
import CheckScreen from  './checkScreen';

const MasterHOC = (props) => {
  return (
    <RequireAuth>
      <CheckScreen>
        {props.children}
      </CheckScreen>
    </RequireAuth>
  )
}

export default memo(MasterHOC);
