import { useState, useEffect } from "react";
import Context from "./Context";
import { useApp } from "../embed";
import { IstateAuthFB } from "./types";
import { queryString } from "services/helper";
import options from "secret/options";
import { AUTH_STATE_DEFAULT } from "./constants";

const initialAuthState: IstateAuthFB = AUTH_STATE_DEFAULT;

const Provider = (props: any) => {
  const { shopStatus } = useApp();
  const [stateAuthFB, setStateAuthFB] =
    useState<IstateAuthFB>(initialAuthState);

  const useEffectDidMount = (effect: React.EffectCallback) => {
    useEffect(effect, [effect]);
  };

  useEffectDidMount(() => {
    if (!stateAuthFB.ready) {
      checkLogin();
    }
    const eventName = "message";
    window.addEventListener(eventName, handleFacebookLoginCallback, false);

    return () => {
      window.removeEventListener(eventName, handleFacebookLoginCallback, false);
    };
  });

  const handleFacebookLoginCallback = (event: any) => {
    if (event.data === "facebook_login_success") {
      setLogedIn(true);
    }
  };

  const checkLogin = () => {
    setLogedIn(shopStatus.isLoginFacebook);
    setFacebookAdsEnabled(shopStatus.isFacebookAdsEnabled);
    setOnboardingCompleted(shopStatus.isOnBoardingCompleted);
    setOnboardingStep(shopStatus.onBoardingStep);
    setUserScreen(shopStatus.userScreen);
    setReady(true);
  };

  const login = () => {
    const _queryString: any = queryString;
    var width = 600;
    var height = 500;
    var left = window.screen.width / 2 - width / 2;
    var top = window.screen.height / 2 - height / 2;
    window.open(
      `${options.api_url}/api/facebooks/login?orgid=${_queryString.orgid}`,
      "popUpWindow",
      `height=${height},width=${width},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`
    );
  };

  const setReady = (payload: boolean) => {
    setStateAuthFB((preState) => ({ ...preState, ready: payload }));
  };

  const setLogedIn = (payload: boolean) => {
    setStateAuthFB((preState) => ({ ...preState, isLogin: payload }));
  };

  const setFacebookAdsEnabled = (payload: boolean) => {
    setStateAuthFB((preState) => ({
      ...preState,
      isFacebookAdsEnabled: payload,
    }));
  };

  const setOnboardingCompleted = (payload: boolean) => {
    setStateAuthFB((preState) => ({
      ...preState,
      isOnboardingCompleted: payload,
    }));
  };

  const setOnboardingStep = (payload: number) => {
    setStateAuthFB((preState) => ({
      ...preState,
      onBoardingStep: payload,
    }));
  };

  const setUserScreen = (payload: number) => {
    setStateAuthFB((preState) => ({
      ...preState,
      userScreen: payload,
    }));
  };

  const ready = () => {
    return stateAuthFB.ready;
  };

  return (
    <>
      {ready() && (
        <Context.Provider
          value={{
            login,
            setLogedIn,
            setOnboardingCompleted,
            setOnboardingStep,
            setUserScreen,
            stateAuthFB,
          }}
        >
          {props.children}
        </Context.Provider>
      )}
    </>
  );
};

export default Provider;
