import {
  CampaignList,
  CampaignAdd,
  CampaignEdit,
  Install,
  Login,
  Onboarding,
  InstallRejected,
  Report,
  ConsultantSale,
  SettingsPage,
} from "../pages";

const PublicRoutes: any = [
  {
    path: "/login/:step",
    component: Login,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/onboarding",
    component: Onboarding,
  },
  {
    path: "/install/rejected",
    component: InstallRejected,
  },
  {
    path: "/install",
    component: Install,
  },
  {
    path: "/register-trial",
    component: ConsultantSale,
  },
];

const ProtectedRoutes: any = [
  {
    path: "/",
    component: CampaignList,
  },
  {
    path: "/campaigns/add",
    component: CampaignAdd,
  },
  {
    path: "/campaigns/:id",
    component: CampaignEdit,
  },
  {
    path: "/campaigns",
    component: CampaignList,
  },
  {
    path: "/reports",
    component: Report,
  },
  {
    path: "/settings",
    component: SettingsPage,
  },
];

export { PublicRoutes, ProtectedRoutes };
