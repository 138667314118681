/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo } from "react";
import { useNavigateOmni } from "hooks";
import { useFBAuth } from "services/auth";

const RequireAuth = memo((props: any) => {
  const navigateOmni = useNavigateOmni();
  const { stateAuthFB } = useFBAuth();

  const useEffectDidMount = (effect: React.EffectCallback) => {
    useEffect(effect, [stateAuthFB]);
  };

  useEffectDidMount((): void => {
    if (!stateAuthFB.isFacebookAdsEnabled) {
      return navigateOmni("/register-trial");
    } else if (!stateAuthFB.isLogin) {
      return navigateOmni("/login");
    } else if (!stateAuthFB.isOnboardingCompleted) {
      return navigateOmni("/onboarding");
    }
  });

  const isReady = () => {
    return (
      stateAuthFB.isFacebookAdsEnabled &&
      stateAuthFB.isOnboardingCompleted &&
      stateAuthFB.isLogin
    );
  };

  return isReady() ? <>{props.children}</> : null;
});

export default RequireAuth;
