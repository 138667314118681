import { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import { PublicRoutes, ProtectedRoutes } from "./routes";
import { MasterLayout } from "./components/Layout";
import { Notfound, NoLanguage } from "pages";
import { AuthProvider } from "services/auth";
import { MasterHOC } from 'services/hocs';
import { AppProvider } from "services/embed";

function App() {
  return (
    <div className="App hrv-fba-mb-150 hrv-fba-sm-mb-0">
      <AppProvider>
        <AuthProvider>
          <Routes>
            {PublicRoutes.map((route: any, index: number) => {
              const Component = route.component;
              let Layout: any = MasterLayout;

              if (route.layout) {
                Layout = route.layout;
              } else if (null === route.layout) {
                Layout = Fragment;
              }

              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout>
                      <Component />
                    </Layout>
                  }
                />
              );
            })}

            {ProtectedRoutes.map((route: any, index: number) => {
              const Component = route.component;
              let Layout: any = MasterLayout;

              if (route.layout) {
                Layout = route.layout;
              } else if (null === route.layout) {
                Layout = Fragment;
              }

              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout>
                      <MasterHOC>
                        <Component />
                      </MasterHOC>
                    </Layout>
                  }
                />
              );
            })}
            <Route path="/no-support-language" element={<NoLanguage />} />
            <Route path="*" element={<Notfound />} />
          </Routes>
        </AuthProvider>
      </AppProvider>
    </div>
  );
}

export default App;
