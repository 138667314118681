import moment from "moment";

export const queryString = new Proxy(
  new URLSearchParams(window.location.search),
  {
    get: (searchParams, prop: any) => searchParams.get(prop),
  }
);

export const amazingHelper = () => {
  return true;
};

export const amazingStoreLog = (reducer: any) => {
  return (preState: any, action: any) => {
    console.group(`_STORE_LOG_DEBUG : ${action.type}`);
    console.log("_Pre State: ", preState);
    console.log("_Action: ", action);
    const nextState = reducer(preState, action);
    console.groupEnd();
    return nextState;
  };
};

export const formatNumber = (
  value: any,
  includeDecimal: boolean = true,
  decimal: number = 2
) => {
  if (includeDecimal) {
    value = parseFloat(value).toFixed(decimal);
  }
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  if (includeDecimal && parseInt(list[1]) > 0) {
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
  }

  return `${prefix}${result}`;
};

export const formatDate = (
  time: any,
  format: string = "YYYY-MM-DD",
  inputFormat: string = "YYYY-MM-DD HH:mm:ss"
) => {
  return moment(time, inputFormat).format(format);
};

export const buildQueryString = (
  queries: any,
  whiteList: any = [],
  bridgeSign: string = "?"
) => {
  const queryString: any = [];
  const checkWhitelist: boolean = whiteList.length > 0 ? true : false;
  Object.keys(queries).forEach((prop) => {
    if (queries[prop] !== "") {
      if (checkWhitelist) {
        if (whiteList.includes(prop)) {
          queryString.push(urlencode(prop) + "=" + urlencode(queries[prop]));
        }
      } else {
        queryString.push(urlencode(prop) + "=" + urlencode(queries[prop]));
      }
    }
  });

  return queryString.length > 0 ? `${bridgeSign}${queryString.join("&")}` : "";
};

export const parseQueryString = (queryString, whiteList: any = []) => {
  const str: string = queryString || window.location.search;
  const objURL: any = {};
  const checkWhitelist: boolean = whiteList.length > 0 ? true : false;

  str.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    ($0, $1, $2, $3): any => {
      if (checkWhitelist) {
        if (whiteList.includes($1)) {
          objURL[$1] = urldecode($3);
        }
      } else {
        objURL[$1] = urldecode($3);
      }
    }
  );

  return objURL;
};

export const urlencode = (str: any) => {
  str = (str + "").toString();

  // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
  return encodeURIComponent(str)
    .replace(/!/g, "%21")
    .replace(/'/g, "%27")
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/\*/g, "%2A")
    .replace(/%20/g, "+");
};

export const urldecode = (str: any) => {
  return decodeURIComponent((str + "").replace(/\+/g, "%20"));
};

export const moneyFormat = (
  number,
  isShort,
  suffix,
  is_vn = true,
  is_decimals = false,
  decimals = 2,
  isBr = false
) => {
  isShort = isShort !== undefined ? isShort : false;
  suffix = suffix !== undefined ? suffix : "VNĐ";
  let br = isBr ? "\n" : "";
  // kiểm tra số là âm hay dương => nếu số âm chuyển thành dương và gắn cờ là số âm ( cờ mặc định là số dương : isNegativeNumber = 1 )
  let isNegativeNumber = 1;
  if (number < 0) {
    number = number * -1;
    isNegativeNumber = -1;
  }
  let unit = "";
  // Tinh đơn vị và rút gọn số
  // Ví dụ: 1.320.000 => 1.320 m (1000 triêu)
  // 234.545.000 => 234 b (234 tỷ)
  if (isShort && !isNaN(number)) {
    if (number > 1000000000) {
      number /= 1000000000.0;
      // ti : billion
      unit = is_vn ? "Tỷ" : "T";
    } else if (number >= 1000000) {
      number /= 1000000.0;
      // trieu : million
      unit = is_vn ? "Triệu" : "Tr";
    } else if (number >= 1000) {
      number /= 1000.0;
      // nghin : thousand
      unit = is_vn ? "Nghìn" : "N";
    }
  }
  // if (number > 100) {
  //     is_decimals = 0;
  // }
  number = formatNumber(number * isNegativeNumber, is_decimals, decimals);
  number = number === -0 ? "0" : number;

  return number === "0" ? number : number + " " + br + unit + " " + suffix;

  //  "thousand" - nghìn, "million" triệu và "billion" tỷ
};

export const slugify = (str: string): string => {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  // Thay ký tự đĐ
  str = str.replace(/[đĐ]/g, "d");

  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, "");

  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, "-");

  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, "-");

  // xóa phần dư - ở đầu & cuối
  str = str.replace(/^-+|-+$/g, "");

  // return
  return str;
};

export const checkEnableCookie = () => {
  let cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled) {
    document.cookie = "facebookadscookiehere";
    cookieEnabled = document.cookie.indexOf("facebookadscookiehere") !== -1;
  }
  return cookieEnabled;
};

export const pushDataStringFilterIds = (
  value: any,
  currentValues: any,
  valueDefault: any = ""
) => {
  if (currentValues === valueDefault) return value;
  const currentValuesArr = currentValues.split(",");
  if (!currentValuesArr.includes(value)) {
    currentValuesArr.push(value);
  }

  return currentValuesArr.join(",");
};

export const handleResponse = (response) => {
  if (response.has_error) {
    return Promise.resolve({
      data: response,
      isError: true,
    });
  } else {
    return Promise.resolve({
      data: response.data || [],
      isError: false,
    });
  }
};

export function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}
