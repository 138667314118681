/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo } from "react";
import { useNavigateOmni } from "hooks";
import { useFBAuth } from "services/auth";
import { USER_SCREEN } from "services/embed/constants";

const CheckScreen = memo((props: any) => {
  const navigateOmni = useNavigateOmni();
  const { stateAuthFB } = useFBAuth();

  const useEffectDidMount = (effect: React.EffectCallback) => {
    useEffect(effect, [stateAuthFB]);
  };

  useEffectDidMount((): void => {
    if (!stateAuthFB.isOnboardingCompleted) {
      return navigateOmni("/onboarding");
    } else if (
      stateAuthFB.userScreen < USER_SCREEN.listing_campaign
    ) {
      /**
        *  Shop đã onboarding nhưng chưa:
        *  login FB hoặc create business hoặc assign page hoặc assign_ad account
        *  => redirect về page Cấu hình
      **/
      return navigateOmni("/settings");
    }
  });

  const isReady = () => {
    // check đã onboarding rồi
    return stateAuthFB.isOnboardingCompleted;
  };

  return isReady() ? <>{props.children}</> : null;
});

export default CheckScreen;
