import { Loading as HLoading } from "@haravan/react-components";

const Loading = (props) => {
  const { height, size } = props;

  return (
    <div
      style={{ height: `${height}px` }}
      className="hrv-fba-d-flex hrv-fba-items-center hrv-fba-justify-center hrv-fba-align-center"
    >
      <HLoading size={size} />
    </div>
  );
};

Loading.defaultProps = {
  height: 560,
  size: "icon",
};

export default Loading;
