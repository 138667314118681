import { LazyLoad } from "utils/Loadable";

// Lazy Import Public Page
const Login = LazyLoad({
  CallBackFnc: () => import("../pages/Login"),
});
const Onboarding = LazyLoad({
  CallBackFnc: () => import("../pages/Onboarding"),
});
const Install = LazyLoad({ CallBackFnc: () => import("../pages/Install") });
const InstallRejected = LazyLoad({
  CallBackFnc: () => import("../pages/Install/Rejected"),
});

// Lazy Import Private Page
const CampaignList = LazyLoad({
  CallBackFnc: () => import("../pages/Campaign/PageList"),
});
const CampaignAdd = LazyLoad({
  CallBackFnc: () => import("../pages/Campaign/PageAdd"),
});
const CampaignEdit = LazyLoad({
  CallBackFnc: () => import("../pages/Campaign/PageEdit"),
});
const Report = LazyLoad({
  CallBackFnc: () => import("../pages/Report"),
  fallback: <p>Loading</p>,
});
const Notfound = LazyLoad({ CallBackFnc: () => import("../pages/Notfound") });
const NoLanguage = LazyLoad({
  CallBackFnc: () => import("../pages/NoLanguage"),
});
const NoMobile = LazyLoad({ CallBackFnc: () => import("../pages/NoMobile") });
const ConsultantSale = LazyLoad({
  CallBackFnc: () => import("../pages/ConsultantSale"),
});
const SettingsPage = LazyLoad({
  CallBackFnc: () => import("../pages/Settings"),
});

export {
  Install,
  Login,
  CampaignList,
  CampaignAdd,
  CampaignEdit,
  Onboarding,
  InstallRejected,
  Report,
  Notfound,
  NoLanguage,
  NoMobile,
  ConsultantSale,
  SettingsPage,
};
